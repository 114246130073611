export default {

    // 三基同创 测试 test文件夹

    os: '',
    osVersion: '',
    deviceId: '',
    appPkgName: '',
    appVersion: '',
    sdkVersion: '',
    channelId: '',
    token: '',
    bind: '',
    notificationId: '',
    language: '',


    pP:"VdTJxh",
    pOs: "Uv",
    pOsVersion: 'UvxNChEid',
    pDeviceId:"JhXRnTeX",
    pAppPkgName:"GsRyvVjUcY",
    pAppVersion:"GsREpgOceh",
    pSdkVersion:"YgMEpgOceh",
    pChannelId:"IkCWyTHCT",
    pToken:"ZrMNy",
    pBind: "HlPM",
    pNotificationId : "TrVRqXyUjcUqkM",
    pLanguage: "RdPPFPCY",

    obj:"uvlbMgEXWY",
    type: "ZBRN",
    subType:"YxDCJeA",
    msg:"SvI",

    audio:"GxFRz",
    image:"OpCPp",
    permission:"VhTVthOceh",
    saveImage:"YdXNTbwaU",
    startRecordAudio:"YwCaEGAWelJDWMtd",
    cancelRecordAudio:"IdPLpanYSiXgcdoXK",
    finishRecordAudio:"LlPRDWnYSiXgcdoXK",

    mode: "SrFN",
    content: "IrPcpcP",


    // 支付相关 
    productList: [],
    status: false,

    channelId: '51dfc6de7a32ee6a ',

    uid: "",
    clientSecret: "",
    customerId: "",
    email: "",
    firstName: "",
    lastName: "",
    noticeList: [],


}