module.exports = {
    language: "中文",
    setting: {
        index: {
            title: "设置",
            problem: "问题反馈",
            order: "订单记录",
            notice: "通知",
            language: "语言",
            clear: "清除缓存",
            clearTip: "你确定要\n清除缓存吗？",
            confirm: "确认",
            cancel: "取消",
        },
        language: {
            title: "语言",
            langList: ["简体中文", "繁体中文", "英语", "阿拉伯语", "波兰语", "波斯语", "丹麦语", "德语", "俄语", "法语", "荷兰语", "捷克语", "罗马尼亚语", "葡萄牙语", "日语", "泰语", "土耳其语", "西班牙语", "希腊语", "意大利语", "越南语", "印地语"],
        },
        order: {
            title: "订单记录",
            success: "获取成功",
            unpaid: "未支付",
            paid: "已支付",
            expired: "已过期",
            refunded: "已退款",
            review: "审核中",
            failed: "退款失败",
            pTime: "付款时间",
            eTime: "过期时间",
            pMethod: "付款方式",
            pObject: "付款对象",
            oNumber: "订单编号",
            pType: "套餐类型",
            oTime: "获取时间",
            watch: "查看IMEI",
            delete: "删除订单",
            refund: "申请退款",
            empty: "暂无记录",

        },
        problem: {
            title: "问题反馈",
            feedback: "反馈类型",
            please: "请选择",
            content: "内容",
            improve: "请写下您的建议，如功能需求、产品投诉等，我们会努力改进",
            upload: "上传图片",
            submit: "提交",
            general: "一般反馈",
            purchase: "购买相关",
            bug: "错误报告",
            suggestion: "建议",
            other: "其他",
        },
        notice: {
            title: "通知",
            type: "通知类型: 系统回复",
            time: "通知时间: "
        }
    },

    subscription: {
        order : {
            number: "订单号",
            name: "订单名称",
            times: "订单次数",
            validity: "有效期",
            buy: "立即购买",
            days: "天数",
            unlock: "解锁所有智能AI",
            text: "文本转语音",
        },
        jarvis: {
            funcList: ["人工智能问答", "人工智能绘画", "基础版智能AI", "高级智能AI", "多模态输入输出", "更详细的问答", "更智能的模型", "更快的响应"],
            times: "次",
            more: "更多高级AI",
            custom: "自定义手表智能体AI列表",
            delay: "您的账户入账可能会有一点延迟。 请耐心等待，退出手表应用后重新进入。",
            member: "会员比较",
            packageName: "套餐名称",
            unlimited: "无限",
            month: "月",
            paySuccess: "支付成功",
        },
        index: {
            nav: {
                l: "专业版",
                r: "次数",
                language: "语言",
                notice: "通知",
                feedback: "反馈",
            },
            proBox: {
                expire: {
                    title: "到期日期",
                    date: "尚未订阅",
                },
                packTip: "我们有多种不同的月度套餐",
                packList: ["至尊订阅", "基础订阅", "普通订阅"],
                benefit: {
                    origin: "原价：",
                    remaining: "剩余",
                    title: "订阅权益",
                    value: "价值",
                    benefitList: [
                        { labelTime: "无限次", labelSave: "图片保存", labelPer: "完全权限" },
                        { labelTime: "300 次", labelSave: "记录存储", labelPer: "基础权限" },
                        { labelTime: "100 次", labelSave: "记录存储", labelPer: "低权限" },
                    ],
                    desc: "$4.99/月包含 100 次免费使用，$9.99/月包含 300 次免费使用。",
                    tip: "您的账户入账次数会有稍许延迟。请稍候并退出手表应用程序，然后重新进入。"
                },
            },
            timeBox: {
                packTip: "我们有多种不同的月度套餐",
                packList: ["新用户优惠次数套餐", "50次套餐", "100次套餐", "300次套餐"],
                times: {
                    title: "次数的作用",
                    timesList: ["可用于绘画", "可用于 GPT 问答", "用于星座占卜", "可用于学习", "用于识别物体"],
                    desc: "50 次使用价格为 2.99 美元，100 次使用价格为 4.99 美元，300 次使用价格为 12.99 美元。0.99 美元的 50 次套餐适用于新用户，并且只能购买一次（24 小时内可用）",
                }
            },
            staticBox: {
                confirm: "确认",
                privacy: "隐私",
                terms: "条款",
                cancel: "随时取消",
                funcTitle: "通过专业版体验释放您的创造力",
                function: "功能",
                free: "免费",
                pro: "专业版",
                funcList: ["次数有效期为 1 天", "10 次绘图/聊天（三次激活机会）", "订阅一次有效期为 30 天", "更多使用次数", "图片永久保存", "购买次数"],
                details: "详情",
            },
            dialog: {
                error: "无法付款，请扫描手表上的二维码。",
                unbind: "您正在更改付款帐户。你想继续吗？",
                confirm: "确认",
                cancel: "取消",
            }
        },
        unsubscribe: {
            title: "取消订阅",
            subTitle: "订阅中",
            unsubscribe: "取消订阅",
            subscribeTime: "订阅时间",
            cardBind: "绑卡成功",
            cardBindDesc: "您的支付账户已成功添加",
            empty: "暂无订阅记录",
            
        },
        refund: {
            title: "申请退款",
            number: "订单号",
            content: "请说明退款原因",
        }
    },
    payment: {
        index: {
            contact: "联系信息",
            please: "请输入您的电子邮件地址",
            email: "付款收据将发送到此电子邮件",
            name: "卡片上的姓名",
            firstName: "名",
            lastName: "姓",
            confidence: "我们不会保留您的任何信息，请放心输入",
            Card: "卡片信息",
            confirm: "通过确认您的订阅，您允许Jarvis根据其条款从您的卡中扣款以进行未来的付款。您可以随时取消订阅。",
            powered: "由stripe提供支持",
            terms: "条款",
            privacy: "隐私",
        }
    },
    policy: {
        title: {
            terms: "条款",
            about: "关于",
            service: "服务条款",
            privacy: "隐私政策",
            refund: "退款政策",
            us: "关于我们",
        },
        service: {
            section1: `<p>位于 https://aiwatchstore.org/ 的网站是 HONG KONG ADFLASH MEDIA LIMITED 的版权作品。网站的某些功能可能需要遵守额外的指南、条款或规则，这些将会与相关功能一起发布在网站上。</p>
<p>所有额外的条款、指南和规则均通过引用纳入这些条款中。</p>
<p>这些使用条款描述了管理您使用本网站的法律约束条款和条件。登录本网站即表示您同意这些条款，并表明您有权力和能力接受这些条款。您应至少年满 18 岁才可访问本网站。如果您不同意这些条款的所有规定，请不要登录和/或使用本网站。</p>
<p>这些条款要求在第 10.2 节中使用仲裁来单独解决争议，并限制在发生争议时您可获得的补救措施。</p>
<h2>访问本网站</h2>
<p><strong>受这些条款约束。</strong> 我们授予您一个不可转让、非独占、可撤销的有限许可，仅供您个人的非商业用途访问本网站。</p>
<p>我们保留变更、中止或终止本网站的权利，无论是否提前通知您。您同意我们对任何变更、中断或终止本网站或其任何部分不承担责任。</p>
<p><strong>无支持或维护义务。</strong> 您同意我们没有义务向您提供与本网站相关的任何支持。</p>
<p>除您可能提供的任何用户内容外，您知道网站及其内容的所有知识产权，包括版权、专利、商标和商业秘密，均由我们或我们的供应商拥有。请注意，这些条款和对网站的访问并未授予您任何知识产权的权利、所有权或利益，除了第 2.1 节中表达的有限访问权。我们及其供应商保留未在这些条款中授予的所有权利。</p>`,
            section2: `<h2>订阅条款</h2>
<p>欢迎使用我们的订阅服务（以下简称“服务”）。该服务由 HONG KONG ADFLASH MEDIA LIMITED（以下简称“我们”或“公司”）提供。使用或订阅我们的服务即表示您同意遵守这些订阅条款（以下简称“条款”）。</p>
<p>我们将为您提供基于订阅的服务，包括专属用户配额。具体的服务项目和费用可能随时变更，并将通过电子邮件或我们的网站通知进行更新。</p>
<p>订阅费用、支付方式和支付周期详见定价页面。逾期付款的账户其付费服务将被暂时中止。</p>
<p>除法律要求或我们自行决定外，所有订阅费用不予退还。</p>
<p>您可以随时取消订阅。取消订阅后，我们将在当前订阅期结束前继续提供服务，但已支付的费用将不予退还。</p>
<p>我们也保留在以下情况下单方面终止或中止您服务的权利：</p>
<p>- 违反这些条款</p>
<p>- 未支付订阅费用</p>
<p>我们将尽一切努力提供高质量的服务，但不对服务的可用性、准确性或完整性做任何明示或暗示的保证。</p>`,
            section3: `<h2>其他用户</h2>
<p><strong>其他用户。</strong> 每个网站用户对其自身的用户内容负全部责任。由于我们不控制用户内容，您承认并同意我们不对任何用户内容负责，无论是您提供的还是其他人提供的。您同意我们不对因任何此类互动而造成的任何损失或损害负责。如果您与任何网站用户之间发生争议，我们没有义务介入。</p>
<p>您在此解除并永久解除我们以及我们的管理人员、员工、代理人、继任者和受让人从而放弃和放任每一个因直接或间接产生的或与网站有关的过去、现在和未来的争议、索赔、争论、需求、权利、义务、责任、诉讼和所有种类性质的诉因。如果您是加州居民，您在此放弃加州民法典第1542条，该条款规定：“一般性豁免不包括债权人在执行豁免时不知道或怀疑存在于其有利的索赔，这些索赔若被其知悉，必会对其与债务人的和解产生重大影响。”</p>
<p><strong>Cookies 和网络信标。</strong> 像任何其他网站一样，我们使用‘cookies’。这些 cookies 被用于存储信息，包括访问者的偏好，以及访问者访问过的网页。该信息用于根据访问者的浏览器类型和/或其他信息自定义我们的网页内容，以优化用户体验。</p>
<h2>免责声明</h2>
<p>本网站按“现状”和“可用”基础提供，我们及我们的供应商明确拒绝任何和所有明示、暗示或法定性质的担保和条件，包括所有适销性、特定用途适用性、所有权、安静享用、准确性或无侵权的担保或条件。我们及我们的供应商不保证本网站将满足您的需求、将在不中断、及时、安全或无错误的基础上可用、或将是准确的、可靠的、无病毒或其他有害代码的、完整的、合法的或安全的。如果适用法律要求对本网站作出任何担保，所有此类担保的期限均限制为自首次使用之日起九十(90)天。</p>
<p>某些司法管辖区不允许排除暗示的担保，因此上述排除可能不适用于您。某些司法管辖区不允许限制暗示担保的持续时间，因此上述限制可能不适用于您。</p>`,
            section4: `<h2>责任限制</h2>
<p>在法律允许的最大范围内，我们或我们的供应商在任何情况下均不对因这些条款或您使用或无法使用本网站而产生的任何利润损失、数据丢失、替代产品的采购成本或任何间接、结果性、示范性、附带、特殊或惩罚性损害承担责任，即使我们已被告知可能会发生此类损害。访问和使用本网站的风险由您自行承担，您将对因此造成的设备或计算机系统的任何损坏或数据丢失承担全部责任。</p>
<p>在法律允许的最大范围内，尽管此处有任何相反规定，我们对因本协议而引起或与之相关的任何损害对您的责任在任何时候均不得超过五十美元（USD 50）。多个索赔的存在不会扩大此限制。您同意我们的供应商对因本协议引起或与之相关的任何责任均不承担责任。</p>
<p>某些司法管辖区不允许限制或排除附带或结果性损害的责任，因此上述限制或排除可能不适用于您。</p>
<p><strong>条款和终止。</strong> 在本节的约束下，只要您使用本网站，这些条款将始终有效。我们可自行决定随时以任何理由暂停或终止您使用本网站的权利，包括因您违反这些条款而使用本网站。在您依据这些条款的权利终止时，您的账户及访问和使用本网站的权利将立即终止。您理解终止您的账户可能涉及从我们的实时数据库中删除与您的账户相关的用户内容。我们对终止您根据这些条款的权利不承担任何责任。即使您的这些条款下的权利被终止，这些条款中的以下条款仍将继续有效：第2节至第2.5节、第3节和第4节至第10节。</p>
<p></p>
<p>赔偿。您将为我们、我们的附属公司及我们的员工、代理人辩护、赔偿并使其免受因您使用服务（包括您的内容、您在连接到服务时开发或提供的产品或服务）及您违反这些条款或适用法律而引起的任何索赔、损失及费用（包括律师费）。</p>
<p>免责声明。服务按“现状”提供。除法律禁止的情况外，我们及我们的附属公司和许可方不对服务作任何（明示、暗示、法定或其他形式的）保证，并拒绝所有保证，包括但不限于对适销性、特定用途适用性、满意质量、非侵权和安静享用的保证，以及任何基于任何交易或使用中的保证。我们不保证服务将不间断、准确或无错误，也不保证任何内容将安全、不丢失或不被更改。</p>`,
            section5: `<h2>版权政策</h2>
<p>我们尊重他人的知识产权，并要求我们网站的用户也这样做。关于我们的网站，我们已采用并实施了一项尊重版权法的政策，该政策规定删除任何侵权材料并终止多次侵犯知识产权（包括版权）的用户。如果您认为我们的一位用户通过使用我们的网站非法侵犯了某作品的版权，并希望删除据称侵权的材料，请提供以下信息，以书面通知（根据 17 U.S.C. § 512(c)）的形式提交给我们指定的版权代理人：</p>
<p>- 您的实体或电子签名；</p>
<p>- 您声称被侵犯版权的作品的识别信息；</p>
<p>- 您声称侵权并要求我们删除的在我们服务上的材料的识别信息；</p>
<p>- 足够的信息使我们能够定位该材料；</p>
<p>- 您的地址、电话号码和电子邮件地址；</p>
<p>- 一份声明，表明您有充分理由相信使用有争议的材料未经版权拥有者、其代理或法律授权；以及</p>
<p>- 一份声明，表明通知中的信息是准确的，并在作伪证的惩罚下，表明您是据称被侵权版权的拥有者或被授权代表版权拥有者行事的人。</p>
<p>请注意，根据 17 U.S.C. § 512(f) 条款，任何在书面通知中对重要事实的误述将自动使投诉方对我们因书面通知和指控的版权侵权而产生的任何损害、费用和律师费承担责任。</p>`,
            section6: `<h2>一般条款</h2>
<p>这些条款可能会偶尔修订，如果我们做出任何重大更改，我们可能会通过发送电子邮件到您提供给我们的最新电子邮件地址和/或在我们的网站上显著发布更改通知来通知您。您有责任向我们提供您最新的电子邮件地址。如果您提供给我们的最新电子邮件地址无效，我们发送包含该通知的电子邮件仍将构成对所述更改的有效通知。任何这些条款的更改将在我们向您发送电子邮件通知或我们在我们的网站上发布更改通知之后的三十(30)个日历日内最早生效。对新用户，这些更改将立即生效。在收到此类更改通知后继续使用我们的网站即表示您承认这些更改并同意受此类更改条款和条件的约束。争议解决。请仔细阅读本仲裁协议。这是您与我们的合同的一部分，影响您的权利。它包含强制性约束仲裁和集体诉讼豁免程序。</p>
<p><strong>仲裁协议的适用性。</strong> 所有因条款或我们提供的任何产品或服务的使用而产生的、无法通过非正式方式或小额索赔法庭解决的索赔和争议，应根据本仲裁协议的条款通过具有约束力的个人仲裁解决。除非另有约定，所有仲裁程序均应以英语进行。该仲裁协议适用于您和我们，以及任何子公司、附属公司、代理人、员工、前任、继任者和受让人，以及所有在条款下提供服务或商品的授权或未经授权的用户或受益人。</p>
<p><strong>通知要求和非正式争议解决。</strong> 在任何一方寻求仲裁之前，必须首先向另一方发送争议通知，描述索赔或争议的性质和依据以及所请求的救济。向我们发送的通知应通过我们的联系电子邮件发送。收到通知后，您和我们可以尝试非正式地解决索赔或争议。如果您和我们在收到通知后三十（30）天内未能解决索赔或争议，任何一方可以开始仲裁程序。任何一方在仲裁员决定双方任何一方有权获得奖励的金额之前，不得向仲裁员透露任何和解提议的金额。</p>
<p><strong>仲裁规则。</strong> 仲裁应通过美国仲裁协会（一家提供仲裁的既定替代性争议解决提供商）启动，具体如本节所述。如果AAA无法进行仲裁，各方应同意选择一个替代的ADR提供商。ADR提供商的规则应管理仲裁的所有方面，除非这些规则与条款冲突。AAA消费者仲裁规则可以在adr.org在线获取或通过拨打1-800-778-7879联系AAA。仲裁应由单一的中立仲裁员进行。任何索赔或争议中寻求的奖励总金额少于一万美元（USD $10,000.00）时，可选择通过具有约束力的非出席仲裁解决。对于寻求的奖励总金额为一万美元（USD $10,000.00）或以上的索赔或争议，听证权应根据仲裁规则决定。除非双方另有约定，任何听证会应在距离您住所一百英里以内的位置进行，除非您居住在美国境外。 如果您居住在美国境外，仲裁员应向各方合理通知任何口头听证会的日期、时间和地点。仲裁员作出的裁决可在任何有管辖权的法院执行。如果仲裁员授予您的金额大于我们在启动仲裁前向您提出的最后一份和解提议，我们将支付您所获裁决金额或$2,500.00，以较大者为准。每一方应承担其因仲裁产生的费用和开支，并应支付等额的ADR提供商的费用和成本。</p>
<p><strong>非出席仲裁的额外规则。</strong> 如果选择非出席仲裁，仲裁应通过电话、在线和/或仅基于书面提交进行；具体方式由发起仲裁的一方选择。除非双方另有约定，仲裁不得涉及任何当事人或证人的个人出席。</p>
<p><strong>时限。</strong> 如果您或我们寻求仲裁，仲裁行动必须在诉讼时效内并在AAA规则对相关索赔规定的任何最后期限内启动和/或提出。</p>
<p><strong>仲裁员的权限。</strong> 如果启动仲裁，仲裁员将决定您和我们各自的权利和责任，并且争议不会与任何其他事项合并或与任何其他案件或当事人联合。仲裁员有权对全部或部分索赔作出决定性裁决。仲裁员有权授予金钱赔偿，并授予适用法律、AAA规则和条款下个人可获得的任何非金钱救济或补救措施。仲裁员应发布书面裁决和决定声明，描述裁决所依据的基本发现和结论。仲裁员具有在个人基础上授予救济的相同权力，正如法院法官所具有的那样。仲裁员的裁决对您和我们是最终的和有约束力的。</p>
<p><strong>放弃陪审团审判。</strong> 双方在此放弃其宪法和法定权利在法庭上由法官或陪审团进行审判，而是选择根据本仲裁协议通过仲裁解决所有索赔和争议。仲裁程序通常比法院适用的规则更有限、更高效且成本更低，且法院对仲裁裁决的审查非常有限。如果您和我们之间在任何州或联邦法院因撤销或执行仲裁裁决或其他原因发生任何诉讼，您和我们均放弃所有陪审团审判的权利，而选择由法官解决争议。</p>
<p><strong>放弃集体或合并行动。</strong> 所有在仲裁协议范围内的索赔和争议必须在个人基础上进行仲裁或诉讼，而非集体基础；多个客户或用户的索赔不得与任何其他客户或用户的索赔联合或合并进行仲裁或诉讼。</p>
<p><strong>保密性。</strong> 仲裁程序的所有方面均应严格保密。除非法律另有要求，否则双方同意保持保密。本条款不应阻止一方向法院提交为执行本协议、执行仲裁裁决或寻求禁止性或衡平法救济所需的任何信息。</p>`,
            section7: `<p><strong>可分割性。</strong> 如果本仲裁协议的任何部分或部分内容根据法律被有管辖权的法院认定为无效或不可执行，则该特定部分或部分内容将无效并且应被切割，而协议的其余部分将继续完全有效。</p>
<p><strong>放弃权利。</strong> 提出索赔的当事人可放弃本仲裁协议中规定的任何或所有权利和限制。此类放弃不应放弃或影响本仲裁协议的任何其他部分。</p>
<p><strong>协议的存续性。</strong> 本仲裁协议将在您与我们的关系终止后继续有效。</p>
<p><strong>小额索赔法庭。</strong> 尽管有上述规定，您或我们均可在小额索赔法庭提起个人诉讼。</p>
<p><strong>紧急衡平救济。</strong> 尽管有上述规定，任何一方均可在州或联邦法院寻求紧急衡平救济，以维持仲裁前的现状。请求临时措施不应视为放弃本仲裁协议下的任何其他权利或义务。</p>
<p><strong>不受仲裁约束的索赔。</strong> 尽管有上述规定，诽谤、违反《计算机欺诈与滥用法》以及侵犯或盗用另一方的专利、版权、商标或商业秘密的索赔不受本仲裁协议的约束。</p>
<p>在任何本仲裁协议允许双方在法院诉讼的情况下，双方在此同意提交加利福尼亚州奈德兰县法院的个人管辖权，以进行此类诉讼。</p>
<p>本网站可能受美国出口管制法的约束，并可能受其他国家的出口或进口法规约束。您同意不直接或间接违反美国出口法律或法规，以任何方式出口、再出口或转让从我们获得的任何美国技术数据或使用此类数据的任何产品。</p>`,
            section8: `<p><strong>电子通信。</strong> 您与我们之间的通信使用电子方式进行，无论您使用网站或向我们发送电子邮件，或无论我们在网站上发布通知或通过电子邮件与您沟通。出于合同目的，您(a)同意以电子形式接收我们的通信；并且(b)同意我们以电子方式向您提供的所有条款和条件、协议、通知、披露和其他通信满足任何法律义务，就如同这些通信是以书面形式提供的一样。</p>
<p><strong>完整条款。</strong> 这些条款构成您与我们之间关于使用本网站的完整协议。我们未能行使或执行这些条款的任何权利或规定不应视为对该权利或规定的放弃。这些条款中的章节标题仅为方便起见，没有任何法律或合同效力。“包括”一词意味着“包括但不限于”。如果这些条款的任何规定被认定为无效或不可执行，其他条款将不受影响，且无效或不可执行的规定将被视为已修改，以使其在法律允许的最大范围内有效和可执行。您与我们的关系是独立承包商的关系，双方均不是对方的代理或合作伙伴。这些条款以及您在其中的权利和义务未经我们事先书面同意，不得由您转让、转包、委托或以其他方式转移，任何违反上述规定的尝试转让、转包、委托或转移均将是无效的。我们可自由转让这些条款。这些条款中规定的条款和条件对受让人具有约束力。</p>
<p><strong>您的隐私。</strong> 请阅读我们的隐私政策。</p>
<p><strong>版权/商标信息。</strong> 版权©。保留所有权利。网站上显示的所有商标、徽标和服务标志都是我们的财产或其他第三方的财产。未经我们或拥有商标的第三方的事先书面同意，不得使用这些标志。</p>
<h2>联系信息</h2>
<p>电子邮件: watch_support@adflash.cn</p>`
        },
        privacy: {
            section1: ` <p>保护您的隐私对我们来说非常重要。我们希望以下声明能帮助您了解我们如何处理您通过互联网偶尔提供给我们的个人识别信息。</p>
<p>基于我们的应用程序和相关服务，您可能会申请使用第三方提供的特殊功能服务。如果您需要注册并使用任何特殊功能服务，请仔细阅读该特殊功能服务的服务条款。通过接受或使用任何特殊功能服务，您确认您已阅读并接受本协议和该特殊功能服务的服务条款，并同意受其约束。</p>
<p>有时我们需要信息以提供您所请求的服务，本隐私声明适用于其产品。</p>
<h2>团队收集和接收的信息。</h2>
<p>当您使用软件和服务时，我们可能会自动收集和存储以下类型的信息：</p>
<p>1. 技术信息。有关您设备的某些技术信息，以及您使用软件和服务以及安装在您设备上的其他第三方软件和应用程序（“其他软件”），包括操作系统和其他软件的类型、名称和版本，包括但不限于它们与软件和服务的交互，ISP（互联网服务提供商）、位置（GPS）、互联网协议（IP）地址、匿名用户标识符、设备配置和标识符（IDFA（用于iOS的广告标识符）、广告ID（用于Android的广告标识符）等）、软件和服务的版本，以及您对软件和服务的配置和设置；</p>
<p>2. 使用信息。有关您使用和与设备互动的某些使用相关信息，包括软件和服务以及其他软件，例如您何时以及如何使用软件和服务以及其他软件。例如，您拥有的应用程序以及您使用的应用程序和使用频率。您查看、使用和访问的广告，您如何使用它们以及您对它们的反应；</p>
<p>3. 社交网络。通过软件和服务在社交网络上的互动；</p>`,
            section2: `<p>4. 注册相关信息。您在注册软件和服务时提供的信息（如适用），例如姓名、家庭或工作地址、电子邮件地址、电话和传真号码、出生日期和性别；</p>
<p>5. 支持相关信息。如果您通过我们的支持部分或通过电子邮件与我们沟通，我们可能还会收集您的电子邮件地址、姓名以及您为获取支持自愿提交的任何信息。当您使用软件和服务时，我们会自动生成一个匿名的内部用户ID，该ID分配给您对软件和服务的使用（“用户ID”），并可能与上述任何信息结合。</p>
<p>6. 设备信息</p>
<p>我们收集关于您设备的信息，包括硬件型号、操作系统和版本、唯一设备标识符（包括SSAID、GAID）、浏览器类型和语言、移动设备电话号码和移动网络信息、国际移动设备识别码（“IMEI”）、广告标识符（“IDFA”）、供应商标识符（“IDFV”）、集成电路卡识别码（“ICCID”）、媒体访问控制（“MAC”）地址、型号和分辨率，这些信息将用于计算使用我们产品和服务的设备数量，并分析设备型号和图形适应性数据。您可以选择不提供某些信息，但这样您可能无法享受我们许多应用程序的功能。我们还收集某些设备信息，以帮助我们在您使用我们的服务时（希望很少发生）遇到崩溃或其他问题时诊断问题。</p>
<p>7. 相机和照片</p>
<p>我们的许多服务需要我们从您的设备相机和照片收集图像和其他信息。只有在您同意后，我们才会访问您的相机和照片。您可以选择不提供这些信息，但这样您可能无法享受我们许多服务的功能。</p>
<p>8. 本地存储</p>
<p>我们可能使用浏览器网络存储（包括HTML 5）和应用数据缓存等机制在您的设备上本地收集和存储信息。我们存储的信息不会与任何第三方共享。</p>
<p>9. 非个人信息</p>`,
            section3: `  <p>非个人信息是以无法与任何特定个人直接关联的形式存在的数据，例如您的Android ID、CPN型号、内存大小、手机IMEI号、手机型号、ROM、已安装应用程序名称和包名、电话运营商、位置、安装、卸载、使用频率等。</p>
<p>我们可能在以下情况下收集和使用非个人信息。为了更好地了解用户行为，解决产品和服务中的问题，改进我们的产品、服务和广告，我们可能会收集非个人信息，例如已安装应用程序名称和包名、安装数据、使用频率、国家、设备和渠道。</p>
<h2>团队如何使用您的信息？</h2>
<p>团队可能将收集的信息用于以下分析、业务和运营目的：</p>
<p>1. 运行和更新软件和服务，并为您提供现有和新的功能及特点；</p>
<p>2. 改进和定制软件和服务；</p>
<p>3. 向您提供个性化内容，包括通过我们或合作伙伴提供的基于兴趣的定向广告；</p>
<p>4. 维护和改进软件和服务的质量和操作；</p>
<p>5. 通知您软件和服务的更新；</p>
<p>6. 向您提供支持并处理您的投诉和/或反馈；</p>
<p>7. 在涉及您使用软件和服务的纠纷情况下采取任何行动；</p>` ,

            section4: `  <p>8. 执行适用于软件和服务的条款和条件，并防止非法活动和滥用行为；</p>
<p>9. 遵守和满足任何适用的法律法规、法律程序或可执行的政府请求。与第三方共享信息。</p>
<h2>儿童隐私</h2>
<p>这些服务不面向13岁以下的任何人。我不会有意收集13岁以下儿童的个人身份信息。如果我发现13岁以下的儿童向我提供了个人信息，我会立即将这些信息从我们的服务器上删除。如果您是家长或监护人并且知道您的孩子向我们提供了个人信息，请与我联系，以便我能采取必要的措施。</p>
<h2>隐私政策的变更</h2>
<p>我可能会不时更新我们的隐私政策。因此，建议您定期查看此页面以了解任何更改。我会通过在此页面上发布新的隐私政策来通知您任何更改。这些更改在发布到该页面后立即生效。</p>
<h2>联系我们</h2>
<p>如果您对我的隐私政策有任何问题或建议，请随时通过以下方式联系我。电子邮件: watch_support@adflash.cn</p>`,
        },
        refund: {
            section1: `<p>感谢您的购买。如果您对您的购买不完全满意，我们愿意帮助您。</p>
<p>要启动退款流程，请按照以下步骤进行操作。</p>
<p>订阅产品（例如：基本订阅）</p>
<p>如果您停止使用但忘记取消订阅，只要您在付款后没有使用任何会话或创建任何图像，我们通常会很乐意退还您最近的订阅付款。</p>
<p>要申请退款，请通过您的账户和反馈表向我们发送退款请求。我们可能需要向您索取特定信息以帮助我们确认您的身份。</p>
<p>数字产品（例如：时间包、订阅）</p>
<p>如果已使用任何时间包和订阅，则不予退款。我们不提供部分或按比例的退款。</p>
<p>您有14个日历天的时间从交易日期起申请退款。</p>
<p>一旦我们收到您的退款请求，我们的工作人员将检查您的会员状态以确定您是否有资格获得退款。</p>`,
            section2: `<p>如果您的退款请求获得批准，您的退款将被处理。退款将记入用于购买的原始[支付方式]。</p>
<p>延迟或未收到的退款</p>
<p>如果您没有收到退款，请先再次检查您的银行账户。</p>
<p>然后联系您的银行信用卡公司，可能需要一些时间才能正式发放退款。</p>
<p>接下来联系您的银行。通常在退款发放之前需要一些处理时间。</p>
<p>如果您已经完成了所有这些操作但仍未收到您的退款，请通过watch_support@adflash.cn联系我们。</p>
<p>促销商品</p>
<p>只有正常价格的商品可以退款，很遗憾促销商品不能退款。</p>`,
        },
        us: {
            section1: `<p>公司信息</p>
<p>名称：香港ADFLASH媒体有限公司（HONG KONG ADFLASH MEDIA LIMITED）</p>
<p>地址：香港中环干诺道139号新泰大厦11楼1101室</p>
<p>电子邮件：watch_support@adflash.cn</p>`,
        },
    }
}
