import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// 映射文件
import stateOfficial from './state/official';
import stateTongchuang from './state/tongchuang'
import stateTest from './state/test'
import stateJs from './state/state'

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

var state = null;
console.log(process.env.VUE_APP_BASE_PATH);
console.log(process.env.VUE_APP_FILE_PATH);
if (process.env.VUE_APP_BASE_PATH === 'official') {
    state = stateOfficial;
} else if(process.env.VUE_APP_BASE_PATH === 'tongchuang') {
    state = stateTongchuang;
} else if(process.env.VUE_APP_BASE_PATH === 'test') {
    state = stateTest;
} else {
    state = stateJs;
}
const store = new Vuex.Store({
    state,getters,mutations,actions
})

export default store

// vuex全局储存数据