import state from "./state/state";

export default {
    SET_OS:(state, os)=> {
        state.os = os;
    },
    SET_OS_VERSION:(state, osVersion)=> {
        state.osVersion = osVersion;
    },
    SET_DEVICE_ID:(state, deviceId)=> {
        state.deviceId = deviceId;
    },
    SET_APP_PKG_NAME:(state, appPkgName)=> {
        state.appPkgName = appPkgName;
    },
    SET_APP_VERSION:(state, appVersion)=> {
        state.appVersion = appVersion;
    },
    SET_SDK_VERSION:(state, sdkVersion)=> {
        state.sdkVersion = sdkVersion;
    },
    SET_CHANNEL_ID:(state, channelId)=> {
        state.channelId = channelId;
    },
    SET_TOKEN:(state, token)=> {
        state.token = token;
    },
    SET_BIND:(state, bind)=> {
        state.bind = bind;
    },
    SET_NOTIFICATION_ID:(state, notificationId)=> {
        state.notificationId = notificationId;
    },
    SET_LANGUAGE:(state, language)=> {
        state.language = language;
    },

    SET_WATCH_ID:(state, watchId)=> {
        state.watchId = watchId;
    },

    // 支付相关
    SET_PRODUCT_LIST:(state, productList) => {
        state.productList = productList;
    },
    SET_STATUS:(state, status) => {
        state.status = status;
    },

    SET_UID:(state, uid)=> {
        state.uid = uid;
    },
    SET_CLIENT_SECRET:(state, clientSecret)=> {
        state.clientSecret = clientSecret;
    },
    SET_CUSTOMER_ID:(state, customerId)=> {
        state.customerId = customerId;
    },

    SET_EMAIL:(state, email)=> {
        state.email = email;
    },
    SET_FIRST_NAME:(state, firstName)=> {
        state.firstName = firstName;
    },
    SET_LAST_NAME:(state, lastName)=> {
        state.lastName = lastName;
    },
    SET_NOTICE_LIST:(state, noticeList)=> {
        state.noticeList = noticeList;
    }



}