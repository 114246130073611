module.exports = {
    // 请帮我把""或者``里的文字翻译成中文, 其他文字不需要改动按原样返回, 不需要做其他回复 
    language:"english",
    
    setting: {
        index: {
            title: "Setting",
            problem: "Problem feedback",
            order: "Order record",
            notice: "Notice",
            language: "Language",
            clear: "Clear Cache",
            clearTip: "Do you want to confirm \n Clear cache?",
            confirm: "Confirm",
            cancel: "Cancel",
        },
        language: {
            title: "language",
            langList: ["Simplified Chinese", "traditional Chinese", "English", "Arabic", "Polish", "Persian", "danish", "German", "Russian", "French", "Dutch", "Czech", "Romanian", "Portuguese", "Japanese", "Thai", "turkish", "spanish", "Greek", "Italian", "Vietnamese", "Hindi"],
        },
        order: {
            title: "Order record",
            success: "Get Success",
            unpaid: "Unpaid",
            paid: "Paid",
            expired: "Expired",
            refunded: "Refunded",
            review: "Under review",
            failed: "Refund failed",
            pTime: "Payment Time",
            eTime: "Expiry Time",
            pMethod: "Payment Method",
            pObject: "Payment Object",
            oNumber: "Order Number",
            pType: "Package Type",
            oTime: "Obtain Time",
            watch: "Watch IMEI",
            delete: "Delete Order",
            refund: "Request refund",
            invoice: "Invoice",
            empty: "No records yet",
        },
        invoice: { 
            invoice: "Invoice from Jarvis",
            download: "Download invoice",
            from: "From",
            to: "To",
            memo: "Memo",
            thank: "Thanks for your business!",
            draft: "DRAFT",
            amount: "Amount due",
        },
        problem: {
            title: "Problem feedback",
            feedback: "Feedback Type",
            please: "Please choose",
            content: "Content",
            improve: "Please write down your suggestions, such as functional requirements, product complaints, etc. We will work hard to improve",
            upload: "Upload image",
            submit: "Submit",
            general: "General Feedback",
            purchase: "Purchase related",
            bug: "Bug Report",
            suggestion: "Suggestion",
            other: "Other",
        },
        notice: {
            title: "Notice",
            type: "Notification Type: System Reply",
            time: "Notification time: "
        }
    },
    subscription: {
        order : {
            number: "Order Number",
            name: "Order Name",
            times: "Order Times",
            validity: "Times Validity",
            buy: "Buy Now",
            days: "Days",
            unlock: "Unlock All Smart AI",
            text: "Text To Speech",
        },
        jarvis: {
            funcList: ["AI Q&A", "AI drawing", "Basic version Smart AI", "Advanced Smart AI", "Multimodal input and output", "More detailed Q&A", "Smarter models", "Faster response"],
            times: "Times",
            more: "More Advanced AI",
            custom: "Custom watch smart AI list",
            delay: "There will be a slight delay in your account being credited.Please wait and exit the watch app and re-enter it.",
            member: "Member Comparison",
            packageName: "Package Name",
            unlimited: "Unlimited",
            month: "Month",
            paySuccess: "Payment successful",
        },
        index: {
            nav: {
                l: "Pro",
                r: "Time",
                language: "Language",
                notice: "Notice",
                feedback: "Feedback",
            },
            proBox: {
                expire: {
                    title: "Expire Date",
                    date: "Not subscribed yet",
                },
                packTip: "We have three different monthly packages",
                packList: ["Supreme Subscription","Basic Subscription","Normal subscription"],
                benefit: {
                    origin: "Original price: ",
                    remaining: "Remaining",
                    title: "Subscription benefits",
                    value: "value",
                    benefitList: [
                        {labelTime: "Unlimited Times",labelSave: "Picture Save", labelPer: "Full Permissions"},
                        {labelTime: "300 Times",labelSave: "Record Storage", labelPer: "Basic Permissions"},
                        {labelTime: "100 Times",labelSave: "Record Storage", labelPer: "Low Privileges"},
                    ],
                    desc: "$3.99/Month comes with 100 free uses, $9.99/Month comes with 300 free uses.",
                    tip: "There will be a slight delay in the number of times being credited to your account. Please wait for a while and exit the watch app and re-enter it."
                },
            },
            timeBox: {
                packTip: "We have three different monthly packages",
                packList: ["New user discount Times package","50 Times package","100 Times package","300 Times package"],
                times: {
                    title: "The role of Times",
                    timesList: ["Can be used for drawing","Available for GPT Q&A","Used for horoscope divination","Can be used for learning","Used to recognize objects",],
                    desc: "50 times use for $2.99, 100 times use for $4.99, 300 times use for $12.99. The $0.99 50-time package is for new users and can only be purchased once (available within 24 hours)",
                }
            },
            staticBox: {
                confirm: "Confirm",
                privacy: "Privacy",
                terms: "Terms",
                refund: "Refund",
                cancel: "Cancel anytime",
                funcTitle: "Unleash your creativity with PRO experience",
                function: "Function",
                free: "Free",
                pro: "Pro",
                funcList: ["Times is valid for one day","10 Times Draw/Chat (Three chances to activate)","Subscribe once for 30 days","More Times to use","Pictures retained forever","Number of times you can purchase"],
                details: "Details",
            },
            dialog: {
                error: "Payment is not available. Please scan the QR code on your watch.",
                unbind: "You are changing your payment account. Do you want to continue?",
                confirm: "Confirm",
                cancel: "Cancel",
            }
        },
        unsubscribe: {
            title: "Cancel subscription",
            subTitle: "Subscribing",
            unsubscribe: "Unsubscribe",
            subscribeTime: "Subscription time",
            cardBind: "Card binding successful",
            cardBindDesc: "Your payment account has been successfully added",
            empty: "No subscription record",
        },
        refund: {
            title: "Request Refund",
            number: "Order Number",
            content: "Please explain the reason for the refund",
        },
    },
    payment: {
        index: {
            contact: "Contact information",
            please: "Please enter your e-mail address",
            email: "Payment receipt will be sent to this Email",
            name: "Name on Card",
            firstName: "First name",
            lastName: "Last name",
            confidence: "We do not retain any of your information, please enter it with confidence",
            Card: "Card Information",
            confirm: "By confirming your subscription, you allow Jarvis to debit your card for future payments in accordance with their terms. You can cancel your subscription at any time.",
            powered: "Powered by stripe",
            terms: "Terms",
            privacy: "Privacy",
        }
    },
    policy: {
        title: {
            terms: "Terms", 
            about: "About",
            service: "Terms of Service",
            privacy: "Privacy Policy",
            refund: "Refund Policy",
            us: "About Us",
        },
        service: {
            section1: `<p>Website located at https://aiwatchstore.org/ is a copyrighted work belonging to HONG KONG ADFLASH MEDIA LIMITED. Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.</p>
            <p>All such additional terms, guidelines, and rules are incorporated by reference into these Terms.</p>
            <p>These Terms of Use described the legally binding terms and conditions that oversee your use of the Site. BY LOGGING INTO THE SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and you represent that you have the authority and capacity to enter into these Terms. YOU SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF THE PROVISION OF THESE TERMS, DO NOT LOG INTO AND/OR USE THE SITE.</p>
            <p>These terms require the use of arbitration Section 10.2 on an individual basis to resolve disputes and also limit the remedies available to you in the event of a dispute.</p>
            <h2>Access to the Site</h2>
            <p><strong>Subject to these Terms.</strong> We grants you a non-transferable, non-exclusive, revocable, limited license to access the Site solely for your own personal, noncommercial use.</p>
            <p>We reserves the right to change, suspend, or cease the Site with or without notice to you.  You approved that We will not be held liable to you or any third-party for any change, interruption, or termination of the Site or any part.</p>
            <p><strong>No Support or Maintenance.</strong> You agree that we will have no obligation to provide you with any support in connection with the Site.</p>
            <p>Excluding any User Content that you may provide, you are aware that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by We or We’s suppliers. Note that these Terms and access to the Site do not give you any rights, title or interest in or to any intellectual property rights, except for the limited access rights expressed in Section 2.1. We and its suppliers reserve all rights not granted in these Terms.</p>`,
            section2:`<h2>Subscription Terms</h2>
            <p>Welcome to our subscription service (hereinafter referred to as the "Service"). This service is provided by HONG KONG ADFLASH MEDIA LIMITED (hereinafter referred to as "we" or "the Company"). By using or subscribing to our service, you agree to comply with these Subscription Terms (hereinafter referred to as the "Terms").</p>
            <p>We will provide you with subscription-based services, including exclusive user quotas. The specific service offerings and fees may change at any time and will be updated through notifications via email or on our website.</p>
            <p>Subscription fees, payment methods, and payment cycles are detailed on the pricing page. Accounts with overdue payments will have their paid services temporarily suspended.</p>
            <p>All subscription fees are non-refundable unless required by law or at our sole discretion.</p>
            <p>You can cancel your subscription at any time. Upon cancellation, we will continue to provide services until the end of the current subscription period, but no refunds will be issued for fees already paid.</p>
            <p>We also reserve the right to unilaterally terminate or suspend your service in the following circumstances:</p>
            <p>- Violation of these Terms</p>
            <p>- Non-payment of subscription fees</p>
            <p>We will make every effort to provide high-quality services, but we do not make any express or implied warranties regarding the availability, accuracy, or completeness of the service.</p>`,
            section3: `<h2>Other Users</h2>
            <p><strong>Other Users.</strong> Each Site user is solely responsible for any and all of its own User Content.  Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others.  You agree that We will not be responsible for any loss or damage incurred as the result of any such interactions.  If there is a dispute between you and any Site user, we are under no obligation to become involved.</p>
            <p>You hereby release and forever discharge the We and our officers, employees, agents, successors, and assigns from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature, that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site. If you are a California resident, you hereby waive California civil code section 1542 in connection with the foregoing, which states: "a general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor."</p>
            <p><strong>Cookies and Web Beacons.</strong> Like any other website uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.</p>
            <h2>Disclaimers</h2>
            <p>The site is provided on an "as-is" and "as available" basis, and We and our suppliers expressly disclaim any and all warranties and conditions of any kind, whether express, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement.  We and our suppliers make not guarantee that the site will meet your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe.  If applicable law requires any warranties with respect to the site, all such warranties are limited in duration to ninety (90) days from the date of first use.</p>
            <p>Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you.  Some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you.</p>`,
            section4: `<h2>Limitation on Liability</h2>
            <p>To the maximum extent permitted by law, in no event shall We or our suppliers be liable to you or any third-party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these terms or your use of, or incapability to use the site even if We has been advised of the possibility of such damages.  Access to and use of the site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.</p>
            <p>To the maximum extent permitted by law, notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from or related to this agreement, will at all times be limited to a maximum of fifty U.S. dollars (u.s. $50). The existence of more than one claim will not enlarge this limit.  You agree that our suppliers will have no liability of any kind arising from or relating to this agreement.</p>
            <p>Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.</p>
            <p><strong>Term and Termination.</strong>  Subject to this Section, these Terms will remain in full force and effect while you use the Site.  We may suspend or terminate your rights to use the Site at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms.  Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately.  You understand that any termination of your Account may involve deletion of your User Content associated with your Account from our live databases.  We will not have any liability whatsoever to you for any termination of your rights under these Terms.  Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2 through 2.5, Section 3 and Sections 4 through 10.</p>
            <p></p>
            <p>Indemnity. You will defend, indemnify, and hold harmless us, our affiliates, and our personnel, from and against any claims, losses, and expenses (including attorneys’ fees) arising from or relating to your use of the Services, including your Content, products or services you develop or offer in connection with the Services, and your breach of these Terms or violation of applicable law.</p>
            <p>Disclaimer. THE SERVICES ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE AND OUR AFFILIATES AND LICENSORS MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) WITH RESPECT TO THE SERVICES, AND DISCLAIM ALL WARRANTIES INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE. WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ACCURATE OR ERROR FREE, OR THAT ANY CONTENT WILL BE SECURE OR NOT LOST OR ALTERED.</p>`,
            section5: `<h2>Copyright Policy.</h2>
            <p>We respects the intellectual property of others and asks that users of our Site do the same.  In connection with our Site, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination of users of our online Site who are repeated infringers of intellectual property rights, including copyrights.  If you believe that one of our users is, through the use of our Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:</p>
            <p>- your physical or electronic signature;</p>
            <p>- identification of the copyrighted work(s) that you claim to have been infringed;</p>
            <p>- identification of the material on our services that you claim is infringing and that you request us to remove;</p>
            <p>- sufficient information to permit us to locate such material;</p>
            <p>- your address, telephone number, and e-mail address;</p>
            <p>- a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and</p>
            <p>- a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</p>
            <p>Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.</p>`,
            section6: `<h2>General</h2>
            <p>These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us and/or by prominently posting notice of the changes on our Site.  You are responsible for providing us with your most current e-mail address.  In the event that the last e-mail address that you have provided us is not valid our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.  Any changes to these Terms will be effective upon the earliest of thirty (30) calendar days following our dispatch of an e-mail notice to you or thirty (30) calendar days following our posting of notice of the changes on our Site.  These changes will be effective immediately for new users of our Site.  Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes. Dispute Resolution. Please read this Arbitration Agreement carefully. It is part of your contract with We and affects your rights.  It contains procedures for MANDATORY BINDING ARBITRATION AND A className ACTION WAIVER.</p>
            <p><strong>Applicability of Arbitration Agreement.</strong> All claims and disputes in connection with the Terms or the use of any product or service provided by the We that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement. Unless otherwise agreed to, all arbitration proceedings shall be held in English.  This Arbitration Agreement applies to you and the We, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under the Terms.</p>
            <p><strong>Notice Requirement and Informal Dispute Resolution.</strong> Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute describing the nature and basis of the claim or dispute, and the requested relief.  A Notice to the We should be sent to our contact email. After the Notice is received, you and the We may attempt to resolve the claim or dispute informally.  If you and the We do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding.  The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award to which either party is entitled.</p>
            <p><strong>Arbitration Rules.</strong> Arbitration shall be initiated through the American Arbitration Association, an established alternative dispute resolution provider that offers arbitration as set forth in this section.  If AAA is not available to arbitrate, the parties shall agree to select an alternative ADR Provider.  The rules of the ADR Provider shall govern all aspects of the arbitration except to the extent such rules are in conflict with the Terms.  The AAA Consumer Arbitration Rules governing the arbitration are available online at adr.org or by calling the AAA at 1-800-778-7879.  The arbitration shall be conducted by a single, neutral arbitrator.  Any claims or disputes where the total amount of the award sought is less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief.  For claims or disputes where the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or more, the right to a hearing will be determined by the Arbitration Rules.  Any hearing will be held in a location within 100 miles of your residence, unless you reside outside of the United States, and unless the parties agree otherwise.  If you reside outside of the U.S., the arbitrator shall give the parties reasonable notice of the date, time and place of any oral hearings. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.  If the arbitrator grants you an award that is greater than the last settlement offer that the We made to you prior to the initiation of arbitration, the We will pay you the greater of the award or $2,500.00.  Each party shall bear its own costs and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR Provider.</p>
            <p><strong>Additional Rules for Non-Appearance Based Arbitration.</strong> If non-appearance based arbitration is elected, the arbitration shall be conducted by telephone, online and/or based solely on written submissions; the specific manner shall be chosen by the party initiating the arbitration.  The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise agreed by the parties.</p>
            <p><strong>Time Limits.</strong> If you or the We pursues arbitration, the arbitration action must be initiated and/or demanded within the statute of limitations and within any deadline imposed under the AAA Rules for the pertinent claim.</p>
            <p><strong>Authority of Arbitrator.</strong> If arbitration is initiated, the arbitrator will decide the rights and liabilities of you and the We, and the dispute will not be consolidated with any other matters or joined with any other cases or parties.  The arbitrator shall have the authority to grant motions dispositive of all or part of any claim.  The arbitrator shall have the authority to award monetary damages, and to grant any non-monetary remedy or relief available to an individual under applicable law, the AAA Rules, and the Terms.  The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based.  The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.  The award of the arbitrator is final and binding upon you and the We.</p>
            <p><strong>Waiver of Jury Trial.</strong> THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement.  Arbitration procedures are typically more limited, more efficient and less expensive than rules applicable in a court and are subject to very limited review by a court.  In the event any litigation should arise between you and the We in any state or federal court in a suit to vacate or enforce an arbitration award or otherwise, YOU AND THE We WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved by a judge.</p>
            <p><strong>Waiver of className or Consolidated Actions.</strong> All claims and disputes within the scope of this arbitration agreement must be arbitrated or litigated on an individual basis and not on a className basis, and claims of more than one customer or user cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.</p>
            <p><strong>Confidentiality.</strong> All aspects of the arbitration proceeding shall be strictly confidential.  The parties agree to maintain confidentiality unless otherwise required by law.  This paragraph shall not prevent a party from submitting to a court of law any information necessary to enforce this Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.</p>`,
            section7: `<p><strong>Severability.</strong> If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.</p>
            <p><strong>Right to Waive.</strong> Any or all of the rights and limitations set forth in this Arbitration Agreement may be waived by the party against whom the claim is asserted.  Such waiver shall not waive or affect any other portion of this Arbitration Agreement.</p>
            <p><strong>Survival of Agreement. </strong>This Arbitration Agreement will survive the termination of your relationship with We.</p>
            <p><strong>Small Claims Court.</strong> Nonetheless the foregoing, either you or the We may bring an individual action in small claims court.</p>
            <p><strong>Emergency Equitable Relief.</strong> Anyhow the foregoing, either party may seek emergency equitable relief before a state or federal court in order to maintain the status quo pending arbitration.  A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Arbitration Agreement.</p>
            <p><strong>Claims Not Subject to Arbitration.</strong> Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud and Abuse Act, and infringement or misappropriation of the other party’s patent, copyright, trademark or trade secrets shall not be subject to this Arbitration Agreement.</p>
            <p>In any circumstances where the foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby agree to submit to the personal jurisdiction of the courts located within Netherlands County, California, for such purposes.</p>
            <p>The Site may be subject to U.S. export control laws and may be subject to export or import regulations in other countries. You agree not to export, re-export, or transfer, directly or indirectly, any U.S. technical data acquired from We, or any products utilizing such data, in violation of the United States export laws or regulations.</p>`,
            section8: `<p><strong>Electronic Communications.</strong> The communications between you and We use electronic means, whether you use the Site or send us emails, or whether We posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from We in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that We provides to you electronically satisfy any legal obligation that such communications would satisfy if it were be in a hard copy writing.</p>
            <p><strong>Entire Terms.</strong> These Terms constitute the entire agreement between you and us regarding the use of the Site. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word "including" means "including without limitation". If any provision of these Terms is held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.  Your relationship to We is that of an independent contractor, and neither party is an agent or partner of the other. These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without We’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.  We may freely assign these Terms.  The terms and conditions set forth in these Terms shall be binding upon assignees.</p>
            <p><strong>Your Privacy.</strong> Please read our Privacy Policy.</p>
            <p><strong>Copyright/Trademark Information.</strong> Copyright ©. All rights reserved.  All trademarks, logos and service marks displayed on the Site are our property or the property of other third-parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.</p>
            <h2>Contact Information</h2>
            <p>Email: watch_support@adflash.cn</p>`
        },
        privacy: {
            section1: `        <p>Protecting your privacy is important to us. We hope the following statement will help you understand how the products deals with the personal identifiable information you may occasionally provide to us via Internet.</p>
            <p>Based on our apps and associated services, you may apply for use of a special functional service provided by a third party. If you need to sign up for and use any special functional service, please carefully read the terms of service for such special functional service. By accepting or using any special functional service, you acknowledge that you have read and accepted this Agreement and the terms of service for such special functional service, and agree to be bound by them.</p>
            <p>Sometimes we need informations to provide services that you request, this privacy statement applies to its products.</p>
            <h2>Information the Team Collects and Receives.</h2>
            <p>When you use the Software & Services, we may automatically collect and store the following types of information:</p>
            <p>1.    Technical Information. Certain technical information regarding your device, and your use of the Software & Services and other third party software and applications installed on your device (the "Other Software"), including the type, name and version of operating system and Other Software including, without limitation, their interactions with the Software & Services, ISP (Internet Service Provider), location(GPS), Internet protocol (IP) address, anonymous user identifiers, device configuration and identifiers (IDFA (Identifier for Advertisers used in iOS), Advertising ID (Identifier for Advertisers used in Android) and the like), version of the Software & Services, and your configuration and settings of the Software & Services;</p>
            <p>2.    Usage Information. Certain usage related information regarding your use and interaction with your device, including the Software & Services and Other Software, such as when and how you Use the Software & Services and Other Software. For example, the applications you have and the applications you use and how frequently you use them. Advertisements that you view, use and access, how you use them and your response to them;</p>
            <p>3.    Social Networks. Your interactions on social networks through the Software & Services;</p>`,
            section2: `        <p>4.    Registration Related Information. Information you provide when you register for the Software & Services (if applicable), such as name, home or work addresses, e-mail address, telephone and fax numbers, birth date and gender;</p>
            <p>5.    Support Related Information. If you communicate with us via our support section or via email, we may also collect your email address, name and any information voluntarily submitted by you in connection with support. When you Use the Software & Services, We automatically generate an anonymous internal user ID, which is assigned to your use of the Software & Services (the "User ID") and may be combined with any of the above information.</p>
            <p>6.    Device Information</p>
            <p>We collect information about your device, including the hardware model, operating system and version, unique device identifiers (including SSAID, GAID), browser type and language, mobile device phone number, and mobile network information，International Mobile Equipment Identity (“IMEI”), Identifier for Advertising (“IDFA”), Identifier for Vendor (“IDFV”), Integrated Circuit Card Identifier (“ICCID”), Media Access Control (“MAC”) address, model and resolution, which will be used by us to calculate the number of devices that use our products and our Services and analyze data on device models and graphics adaptation.You can choose not to provide certain information, but then you might not be able to take advantage of many of our APP. We also collect certain device information that will help us diagnose problems in the (hopefully rare) event you experience any crash or other problem while using our Services.</p>
            <p>7.Camera and Photos</p>
            <p>Many of our Services require us to collect images and other information from your device’s camera and photos. We’ll access your camera and photos only after you give us your consent. You can choose not to provide it, but then you might not be able to take advantage of many of our Services.</p>
            <p>8. Local storage</p>
            <p>We may collect and store information locally on your device using mechanisms such as browser web storage (including HTML 5) and application data caches. The information we store will not share with any third-party.</p>
            <p>9.  NON-PERSONAL INFORMATION</p>`,
            section3: `        <p>    Non-personal information is data in a form that does not permit direct association with any specific individual, such as your Android ID, CPN model, memory size, your phone IMEI number, phone model, rom, installed application name and package name, phone operator, location, install, uninstall, frequency of use, etc.</p>
            <p>    We may collect and use non-personal information in the following circumstances. To have a better understanding in user’s behavior, solve problems in products and services, improve our products, services and advertising, we may collect non-personal information such as installed application name and package name, the data of install, frequency of use, country, equipment and channel.</p>
            <h2>How the Team Uses Your Information?</h2>
            <p>The Team may use the information it collects for analytical, business and operational purposes in the following ways:</p>
            <p>1.    to operate and update the Software & Services and provide you with existing and new functionality and features;</p>
            <p>2.    to improve and customize the Software & Services;</p>
            <p>3.    to provide you personalized content, including through the delivery of targeted interest-based advertisements from us or our partners;</p>
            <p>4.    to maintain and improve the quality and operation of the Software & Services;</p>
            <p>5.    to notify you of Software & Services updates;</p>
            <p>6.    to provide you with support and handle your complaints and/or feedback;</p>
            <p>7.    to take any action in case of a dispute involving you in connection with the Software & Services;</p>`,
            section4: `        <p>8.    to enforce the terms and conditions applicable to the Software & Services and to prevent unlawful activities and misuse thereof;</p>
            <p>9.    to comply and satisfy any applicable law regulation, legal process or enforceable governmental request. Sharing Information with Third Parties.</p>
            <h2>Children’s Privacy</h2>
            <p>These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary actions.</p>
            <h2>Changes to This Privacy Policy</h2>
            <p>I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at .Email: watch_support@adflash.cn</p>`,


        },
        refund: {
            section1:`<p>Thank you for your purchase. If you are not completely satisfied with your purchase, we would like to help you.</p>
            <p>To initiate the refund process, please follow the steps below.</p>
            <p>Subscription products (e.g. Basic Subscription)</p>
            <p>If you stop using but forget to cancel your subscription, we will usually be happy to refund your most recent subscription payment, as long as you have not used any sessions or created any images after the payment was made.</p>
            <p>To request a refund, please send us a refund request through your account and through the feedback form. We may need to ask you for specific information to help us confirm your identity.</p>
            <p>Digital products (e.g. Time packs, subscriptions)</p>
            <p>No refunds will be given if any Time packs and subscriptions have been used. We do not offer partial or prorated refunds.</p>
            <p>You have 14 calendar days from the date of the transaction to request a refund.</p>
            <p>Once we receive your refund request, our staff will check your membership status to determine if you are eligible for a refund.</p>`,
            section2: `<p>If you are approved, your refund will be processed. The refund will be credited to the original [payment method] used for the purchase.</p>
            <p>Delayed or missing refunds</p>
            <p>If you have not received your refund, please check your bank account again first.</p>
            <p>Then contact your bank card company, it may take some time for your refund to be officially released.</p>
            <p>Next contact your bank. It usually takes some processing time before the refund is released.</p>
            <p>If you have done all of this and still have not received your refund, please contact us at watch_support@adflash.cn</p>
            <p>Sale Items</p>
            <p>Only regular priced items can be refunded, unfortunately sale items cannot be refunded.</p>`,
        },
        us: {
            section1:`<p>Company Info</p>
            <p>Name: HONG KONG ADFLASH MEDIA LIMITED</p>
            <p>Address: RM 1101 11/F SAN TOI BUILDING NO.139 CONNAUGHT ROAD CENTRAL HK</p>
            <p>Email: watch_support@adflash.cn</p>`,
        },
    }
}