<template>
    <div class="mask" v-if="desc != null && desc != ''">
        <div class="dialog-container">
            <div class="body">
                <img src="@/assets/img/icon_tryagain.png" alt="Try Again" />
                <p class="">{{ desc }}</p>
            </div>
            <div class="button" @click="closeDialog">Confirm</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErrorDialog",
    data() {
        return {
            
        };
    },
    props: {
        errType: Number,
        errInfo: Object,
    },
    mounted() {
    },
    computed: {
        desc() {
            if(this.errorType == 0) {
                return ""
            }else if(this.errType == 1) { //网络
                return this.networkError();
            }else if(this.errType == 2) { //空返回
                return this.nullError();
            }
            // 过期和次数 以及空返回
        }
    },

    methods: {
        networkError() {
            if(navigator.onLine) {
                return "Unstable Internet Connection\n Please Try Again" //网络不稳定
            }else {
                return "No Internet Connection\n Please Try Again";  //无网络
            }
        },
        nullError() {
            const info = this.errInfo;
            if(info.remaining_days == 0) {
                if(info.userType == 0) {
                    return "Free trial limit has been reached.Please subscribe through the APP to continue using it." // 非订阅到期
                } else {
                    return "Subscription has expired Please subscribe throughthe APP to continue using it." // 订阅到期
                }
            }else if(info.total_number == 0) {
                if(info.userType == 0) {
                    return "Today's free trial limit has been reached. Please subscribe through the APP to continue using it." // 非订阅 次数
                } else {
                    if(info.type == "chat") { // 订阅 次数
                        return "The number of questions has been used up, please purchase a question pack through the APP" 
                    }else if(info.type == "draw") {
                        return "The number of Al-generated image has been used up, please purchase a question pack through the APP"
                    } else if (info.type == "astrol") {
                        return "The number of daily horoscope has been used up, please purchase a question pack through the APP";
                    }
                }
            }else {
                if(info.type == "chat") { // 返回空
                    return "" 
                }else if(info.type == "draw") {
                    return "Please enter standardwords and sentences to facilitate Al reading and drawing"
                } else if (info.type == "astrol") {
                    return "Al server is busy please try again.This horoscope does not consume your credits";
                }
            }
        },
        closeDialog() {
            this.$emit('closeDialog')
        }
    },
};
</script>

<style lang="scss" scoped>
.mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}
.dialog-container {
    width: 92vw;
    .body {
        padding: 5vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border-radius: 2.8vw 2.8vw 0 0;
        min-height: 40vw;
        img {
            width: 10vw;
            height: 9.4vw;
            margin-bottom: 5vw;
        }
        p {
            color: #010002;
            font-size: 5vw;
            white-space: pre-wrap;
            text-align: center;
        }
    }
    .button {
        height: 13vw;
        background-color: #0e0b1e;
        border-radius: 0 0 2.8vw 2.8vw ;
        line-height: 13vw;
        font-size: 4.2vw;
        text-align: center;
        color: #ffffff;
    }
}



</style>