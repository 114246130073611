import state from './state/state';

export default {
    getOs:(state)=> {
        return state.os;
    },
    getDeviceId:(state)=> {
        return state.deviceId;
    },
    getAppPkgName:(state)=> {
        return state.appPkgName;
    },
    getAppVersion:(state)=> {
        return state.appVersion;
    },
    getSdkVersion:(state)=> {
        return state.sdkVersion;
    },
    getChannelId:(state)=> {
        return state.channelId;
    },
    getToken:(state)=> {
        return state.token;
    },
    getBind:(state)=> {
        return state.bind;
    },
    getLanguage:(state)=> {
        return state.language;
    },

    // 参数解析
    getParams:(state)=> {
        return {
            os: state.os ,
            osVersion: state.osVersion,
            deviceId: state.deviceId ,
            appPkgName: state.appPkgName ,
            appVersion: state.appVersion ,
            sdkVersion: state.sdkVersion ,
            channelId: state.channelId ,
            token: state.token ,
            bind: state.bind ,
            notificationId: state.notificationId ,
            language: state.language ,
        }
    },


    
    // getPP:(state)=> {
    //     return state.pP;
    // },
    // getPOs:(state)=> {
    //     return state.pOs;
    // },
    // getPOsVersion:(state)=> {
    //     return state.pOsVersion;
    // },
    // getPDeviceId:(state)=> {
    //     return state.pDeviceId;
    // },
    // getPAppPkgName:(state)=> {
    //     return state.pAppPkgName;
    // },
    // getPAppVersion:(state)=> {
    //     return state.pAppVersion;
    // },
    // getPSdkVersion:(state)=> {
    //     return state.pSdkVersion;
    // },
    // getPChannelId:(state)=> {
    //     return state.pChannelId;
    // },
    // getPToken:(state)=> {
    //     return state.pToken;
    // },
    // getPBind:(state)=> {
    //     return state.pBind;
    // },
    // getPNotification:(state)=> {
    //     return state.pNotificationId;
    // },
    // getPLanguage:(state)=> {
    //     return state.pLanguage;
    // },




    // getObj:(state)=> {
    //     return state.obj;
    // },
    // getType:(state)=> {
    //     return state.type;
    // },
    // getSubType:(state)=> {
    //     return state.subType;
    // },
    // getMsg:(state)=> {
    //     return state.msg;
    // },


    // getAudio:(state)=> {
    //     return state.audio;
    // },
    // getImage:(state)=> {
    //     return state.image;
    // },


    // getPermission:(state)=> {
    //     return state.permission;
    // },
    // getSaveImage:(state)=> {
    //     return state.saveImage;
    // },
    // getStartRecordAudio:(state)=> {
    //     return state.startRecordAudio;
    // },
    // getCancelRecordAudio:(state)=> {
    //     return state.cancelRecordAudio;
    // },
    // getFinishRecordAudio:(state)=> {
    //     return state.finishRecordAudio;
    // },
    // getMode:(state)=> {
    //     return state.mode;
    // },
    // getContent:(state)=> {
    //     return state.content;
    // },

    // sdk 交互
    // getInteraction:(state)=> {
    //     return {
    //         obj: state.obj,
    //         type: state.type,
    //         subType: state.subType,
    //         msg: state.msg,
    //         audio: state.audio,
    //         image: state.image,
    //         permission: state.permission,
    //         saveImage: state.saveImage,
    //         startRecordAudio: state.startRecordAudio,
    //         cancelRecordAudio: state.cancelRecordAudio,
    //         finishRecordAudio: state.finishRecordAudio,
    //         mode: state.mode,
    //         content: state.content,
    //     }
    // },

    // getWatchId:(state) => {
    //     return state.watchId;
    // },

    // 支付参数
    getProductList:(state) => {
        return state.productList;
    },
    getStatus: (state) => {
        return state.status;
    },

    getUid:(state) => {
        return state.uid;
    },
    getClientSecret:(state) => {
        return state.clientSecret;
    },
    getCustomerId:(state) => {
        return state.customerId;
    },
    getEmail:(state) => {
        return state.email;
    },
    getFirstName:(state) => {
        return state.firstName;
    },
    getLastName:(state) => {
        return state.lastName;
    },
    getNoticeList:(state) => {
        return state.noticeList;
    }


}