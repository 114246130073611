<template>
    <div id="app">
        <keep-alive exclude="WatchOrderPage,WatchPayment,WatchPayUnsubscribe,ProblemPage,RefundPage,InvoicePage,WatchNoticeDetail">
            <router-view />
        </keep-alive>
    </div>
</template>

<script>
import { langChange } from '@/utils/language.js'
// import { postIP } from '@/api/pay';

export default {
    name: "App",
    data() {
        return {
            // params: {},
        };
    },
    computed: {

    },
    // mounted() {
    //     this.encryp();
    //     console.log("mounted-----------");
    // },
    created() {
        // 默认语言
        // if(localStorage.getItem("i18nLanguage") != null && localStorage.getItem("i18nLanguage") != ''){
        //     this.$i18n.locale = localStorage.getItem("i18nLanguage");
        //     console.log(localStorage.getItem("i18nLanguage"));
        // }
        this.$i18n.locale = langChange(navigator.languages);
        // console.log(this.$i18n.locale);
        // this.getIP(); 
    },
    updated() {
        // console.log("updated--------------------");
        // this.test();
    },
    methods: {
        // test() {
            // console.log("test____");s
            // this.$router.push({name:'drawPage', params: { activity: 2}});
        // }
        // getIP() {
        //     postIP().then(res => {
        //         console.log(res.data);
        //     }).catch(err => {
        //         console.log(err);
                
        //     })
        // }
    },
};
</script>

<style>
img,
p {
    user-select: none;
}
* {
    margin: 0;
    padding: 0;
    border: 0;
}
#app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@import "./assets/font/font.css";
* {
    font-family: "SFUIDisplay-Regular";
}
.el-scrollbar .el-scrollbar__bar {
    opacity: 1 !important;
}

</style>
